import React, { useState } from "react";
import { Section, SubSection } from "~components/text-helpers";
import Latex from "react-latex";
import { graphql, Link } from "gatsby";
import Img, { FixedObject, FluidObject } from "gatsby-image";
import { css } from "@emotion/react";
import { Table } from "antd";

import {
  Code,
  Response,
  Attribute,
  IL,
  Remark,
  VSpace,
  Warning,
  SideBySideCode,
  APIMethod,
  APIReferenceiTHOR,
} from "~components/documentation";
import { UnityAPI } from "~components/api-data";

export default function iTHORDocReference({ data, location }) {
  const [highlightedParam, setHighlightedParam] = useState("");
  return (
    <APIReferenceiTHOR
      columnKey="domainRandomization"
      pageName="Domain Randomization"
    >
      <Section sectionTitle="Material Randomization" emoji="tomato">
        <APIMethod
          location={location}
          methodKey="RandomizeMaterials"
          parentAPI={UnityAPI.BaseAgent}
          setHighlightedParam={setHighlightedParam}
          highlightedParam={highlightedParam}
        />
      </Section>
      <Section sectionTitle="Lighting Randomization" emoji="bulb">
        <APIMethod
          location={location}
          methodKey="RandomizeLighting"
          parentAPI={UnityAPI.BaseAgent}
          setHighlightedParam={setHighlightedParam}
          highlightedParam={highlightedParam}
        />
      </Section>
      <Section sectionTitle="Initial Random Spawn" emoji="tophat">
        <p>
          <IL>InitialRandomSpawn</IL> attempts to randomize the position of{" "}
          <IL>Pickupable</IL> objects, placing them in any valid receptacle they
          could be placed in within the scene. Objects are not guaranteed to
          move from their default position, as sometimes there is not enough
          free space in the scene for an object to move.
        </p>
        <Warning>
          <p>
            After calling initial random spawn, each object will have its
            objectId recomputed (in the form of "objectType|x|y|z"). Thus,
            objects that move will have their objectId's changed. This is
            necessary to prevent collisions between objectId's, since initial
            random spawn may be used to duplicate existing objects in the scene.
          </p>
        </Warning>
        <SideBySideCode
          example={[
            'controller.step(',
            '    action="InitialRandomSpawn",',
            "    randomSeed=0,",
            "    forceVisible=False,",
            "    numPlacementAttempts=5,",
            "    placeStationary=True,",
            "    numDuplicatesOfType = [",
            "        {",
            '            "objectType": "Statue"',
            '             "count": 20',
            "        },",
            "        {",
            '            "objectType": "Bowl"',
            '            "count": 20,',
            "        }",
            "    ],",
            '    excludedReceptacles=["CounterTop", "DiningTable"],',
            '    excludedObjectIds=["Apple|1|1|2"]',
            ")",
          ]}
          argsTitle="Initial Random Spawn Parameters"
        >
          <Attribute
            hashPrefix="random-spawn"
            name="randomSeed"
            type="int"
            default="0"
            description={
              <p>
                Used to seed the randomization for duplicatable scene states.
                Because this seed depends on the current state of the scene,
                remember to reset the scene with controller.reset() before
                running InitialRandomSpawn(), otherwise the seeded randomization
                will not be accurate.
              </p>
            }
          />
          <Attribute
            hashPrefix="random-spawn"
            name="forceVisible"
            type="bool"
            default="False"
            description={
              <p>
                When enabled, the scene will attempt to randomize all moveable
                objects outside of receptacles in plain view. Use this if you
                want to avoid objects spawning out of view inside closed
                drawers, cabinets, etc.
              </p>
            }
          />
          <Attribute
            hashPrefix="random-spawn"
            name="numPlacementAttempts"
            type="int"
            default="5"
            description={
              <p>
                How many times each object in the scene attempts to randomly
                spawn. Setting this value higher will lead to fewer spawn
                failures at the cost of performance.
              </p>
            }
          />
          <Attribute
            hashPrefix=""
            name="placeStationary"
            type="bool"
            default="True"
            description={
              <p>
                Determines if spawned objects will be settled completely static
                and unmoving, or if non-determenistic physics resolve their
                final position. Setting this to False will allow physics to
                resolve final positions, which can be used to spawn an object on
                a sloped receptacle but have it end up rolling off.
              </p>
            }
          />
          <Attribute
            hashPrefix="random-spawn"
            name="numDuplicatesOfType"
            type="list[dict[str, any]]"
            default="[]"
            description={
              <p>
                Used to specify how many objects of a certain type will attempt
                to be duplicated somewhere in the scene. It does not guarantee
                this number of duplicated objects, only the number of attempted
                spawned objects, so this is the max it will be. This will only
                create copies of objects already in the scene, so if you request
                an object which is not in the scene upon reset, it will not
                work.
              </p>
            }
          />
          <Attribute
            hashPrefix="random-spawn"
            name="excludedReceptacles"
            type="list[str]"
            default="[]"
            description={
              <p>
                A list of receptacle object types to exclude from valid
                receptacles that can be randomly chosen as a spawn location. An
                example use case is excluding all CounterTop receptacles to
                allow for a scene configuration that has more free space on
                CounterTops in case you need free space for interaction. Note
                that this will not guarantee all listed receptacles as being
                completely clear of objects, as any objects that failed to
                reposition will remain in their default position, which might
                have been on the excluded receptacle type. Check the Actionable
                Properties section of the Objects documentation for a full list
                of Receptacle objects.
              </p>
            }
          />
          <Attribute
            hashPrefix="random-spawn"
            name="excludedObjectIds"
            type="list[str]"
            default="[]"
            description={
              <>
                <p>
                  Used to prevent certain objects from changing in position.
                </p>
                <Remark>
                  <p>This is often nice for rearrangement related tasks.</p>
                </Remark>
              </>
            }
          />
        </SideBySideCode>
      </Section>
      <Section sectionTitle="Color Randomization" emoji="art">
        <APIMethod
          location={location}
          methodKey="RandomizeColors"
          parentAPI={UnityAPI.BaseAgent}
          setHighlightedParam={setHighlightedParam}
          highlightedParam={highlightedParam}
        />
      </Section>
    </APIReferenceiTHOR>
  );
}
